<template>
  <div>
    <b-row class=" " style="">
      <b-col
        v-for="service of services"
        :key="service.id"
        lg="4"
        md="6"
        sm="12"
        @click="$emit('selectItem', service)"
      >
        <div
          class="restaurant my-1 px-0 position-relative overflow-auto w-100"
          style="background: #eeeeeeff;min-height: 140px"
        >
          <div
            class="float-left"
            style="width: 50%; max-height: 150px; max-width: 170px; min-width: 100px;min-height:
         100px"
          >
            <b-img-lazy
              :src="service.image"
              round
              class="w-100"
              width="170"
              height="150"
              style=""
              :alt="service.name + ' image'"
            />
          </div>
          <div
            class="restaurant-details d-flex flex-column justify-content-between h-100"
            style="width: auto; padding: 3px"
          >
            <!--            Name-->
            <div
              class=" text-dark font-medium-4 mx-1 font-circular"
              style="margin-top: 3px;margin-bottom: 3px;max-height: 68px; overflow: hidden;
           text-overflow: ellipsis; -webkit-line-clamp: 3; display: -webkit-box; -webkit-box-orient: vertical;"
            >
              {{ service.name }}
            </div>
            <!--            Instructions-->
            <div class="text-dark font-medium-1 font-weight-bold ml-1" style="margin: 5px 0">
              <span class="d-flex align-items-center w-100">
                <span
                  v-if="
                    (requestedServices.services.includes(service.id) &&
                      requestedServices.data[service.id].instructions) ||
                      deliveryInstructions[service.id]
                  "
                  v-b-modal="service.id"
                  class="ml-0"
                  style="max-width: 80%; max-height: 36px; overflow: hidden;
           text-overflow: ellipsis; -webkit-line-clamp: 2; display: -webkit-box; -webkit-box-orient: vertical;"
                >
                  {{
                    requestedServices.services.includes(service.id) &&
                    requestedServices.data[service.id].instructions
                      ? requestedServices.data[service.id].instructions
                      : deliveryInstructions[service.id]
                  }}
                </span>
                <span
                  v-else-if="!requestedServices.services.includes(service.id)"
                  v-b-modal="service.id"
                  >Add note{{ deliveryInstructions[service.id] }}</span
                >
                <feather-icon
                  v-if="!requestedServices.services.includes(service.id)"
                  v-b-modal="service.id"
                  icon="EditIcon"
                  size="18"
                  style="min-width: 18px"
                  class="ml-1"
                />
              </span>
            </div>
            <!--            Action Button-->
            <div class="d-flex justify-content-start m-1">
              <b-button
                v-if="requestedServices.services.includes(service.id)"
                variant="outline-danger"
                @click="cancelService(service)"
              >
                Cancel Request
              </b-button>
              <b-button
                v-else
                variant="dark"
                style="border-radius: 0"
                @click="requestService(service)"
              >
                Call Service
              </b-button>
            </div>
          </div>
        </div>
        <b-modal :id="service.id" centered ok-only ok-title="Save" :title="'Instructions'">
          <b-form-textarea
            id="textarea-state"
            v-model="deliveryInstructions[service.id]"
            :disabled="requestedServices.services.includes(service.id)"
            class="font-weight-bold font-medium-1 text-dark"
            style="border-width: 2px;"
            placeholder="Add any special request (e.g., Leave the food at doorstep, and ring the bell once!, etc.) and the delivery person will do its best to accommodate you."
            rows="5"
          />
        </b-modal>
      </b-col>
    </b-row>
  </div>
</template>

<style>
.items.card {
  border-radius: 2rem;
}
.items > .card-body {
  padding: 10px 0;
}
.items > .card-body .card-body {
  padding: 0;
}
</style>

<script>
import { BImgLazy, BCol, BRow, BModal, BFormTextarea, BButton } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BImgLazy,
    BModal,
    BCol,
    BRow,
    BFormTextarea,
    BButton,
    // BNavItemDropdown,
    // BDropdownItem,
    // BDropdownDivider,
    // BAvatar,
  },
  props: {
    services: {
      type: Array,
      default: () => [
        {
          name: 'Room Cleaning',
          id: 'room-cleaning',
          status: '',
          instructions: null,
          // eslint-disable-next-line global-require
          image: require('@/assets/images/housekeeping/room-cleaning.jpeg'),
        },
        {
          name: 'Laundry Service',
          id: 'laundry-serice',
          status: '',
          instructions: null,
          // eslint-disable-next-line global-require
          image: require('@/assets/images/housekeeping/laundry-service.jpeg'),
        },
        {
          name: 'Hygiene and Appliance',
          id: 'hygiene-and-appliances',
          status: '',
          instructions: null,
          // eslint-disable-next-line global-require
          image: require('@/assets/images/housekeeping/hygiene-and-appliances.jpeg'),
        },
      ],
    },
  },
  data() {
    return {
      requestedServices: JSON.parse(
        localStorage.getItem('requestedServices') || '{"services":[],"data":{}}'
      ),
      deliveryInstructions: {},
      badges: {
        popular: {
          title: 'POPULAR',
          variant: 'primary',
        },
        fast: {
          title: 'FAST DELIVERY',
          variant: 'primary',
        },
        free: {
          title: 'FREE DELIVERY',
          variant: 'primary',
        },
      },
    }
  },
  created() {},
  methods: {
    cancelService(service) {
      this.requestedServices.services = this.requestedServices.services.filter(
        item => item !== service.id
      )
      delete this.requestedServices.data[service.id]
      localStorage.setItem('requestedServices', JSON.stringify(this.requestedServices))
      this.showError('Request Cancelled Successfully.')
    },
    requestService(service) {
      this.requestedServices.services.push(service.id)
      this.requestedServices.data[service.id] = {
        id: service.id,
        status: 'requested',
        instructions: this.deliveryInstructions[service.id],
      }
      localStorage.setItem('requestedServices', JSON.stringify(this.requestedServices))
      this.orderComplete()
    },
    orderComplete() {
      this.$toast({
        component: ToastificationContent,
        position: 'top-center',
        props: {
          title: 'Requested Successfully',
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    },
    showError(errorMsgText) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-center',
        props: {
          title: errorMsgText,
          icon: 'AlertCircleIcon',
          variant: 'danger',
        },
      })
    },
  },
}
</script>
